.search-result {
}

.lex-cases-pad {
  margin-bottom: 1em;
}

.search-result-new-case {
  border: 0.5px solid lightgray;
  padding: 0.5em;
  background-color: white;
  margin-bottom: 0.25em;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 0.25em;
  box-shadow: 1px 1px 5px -3px rgba(0, 0, 0, 0.6);

  font-size: 0.8em;

  &-header-wrapper {
    position: sticky;
    width: 100%;
    top: 0;
    // top: 0;
    // left: 1em;
    // right: 1em;
    z-index: 100;
  }

  &.header {
    background-color: #43a9fc;
    align-items: center;
    font-size: 0.9em;

    .search-result-new-case-cell {
      font-weight: 900;
    }
    // color: white;
  }

  &-cell {
    font-size: 1em;
    font-weight: 400;
    width: 15%;
    padding: 0 0.25em;
    overflow: hidden;
    &.x-small {
      width: 5% !important;
    }

    &.small {
      width: 10% !important;
    }

    &.large {
      width: 20% !important;
    }

    &.break-word {
      word-wrap: break-word;
    }
  }
}
