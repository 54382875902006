$mobile-menu-height: 5.25rem;

.mobile-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;

  padding-bottom: 1.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: $mobile-menu-height;
  background: #0d0611;
  border-top: 1px solid #afa1b8;

  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: white;
    width: 20%;
    height: 100%;
    padding: 0 0.25em;
    border-top: solid 2px #0d0611;

    &-icon {
      font-size: 1.5em;
    }
    &-text {
      font-size: 0.6em;
      text-align: center;
    }

    &-floating-count {
      position: absolute;
      top: 0;
      right: 50%;
      transform: translateX(100%);
      background: red;
      color: white;
      padding: 0 0.35rem;
      height: 1.125rem;
      font-size: 0.8rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      color: #b09fff;
      border-top: solid 2px currentColor;
    }
  }
}

.lex-app.lex-mobile {
  .home {
    .nav-tabs {
      width: 100%;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .nav-item {
      width: 50%;

      & .nav-link {
        width: 100%;

        // background-color: lightgray;
        color: black;
        opacity: 0.5;
        border-bottom: 0;
        &.active {
          font-weight: 700;
          opacity: 1;
        }
      }
    }

    .tab-content {
      height: 100%;
      overflow-y: scroll;
      .card {
        position: relative;
        border-top: none;
        margin-bottom: 3rem;
        // height: max-content;
      }
    }
  }

  .lex-banner {
    &.warning {
      background-color: rgb(251, 133, 133);
      margin: 0 0.5em;
      font-weight: 700;
    }
  }

  #docviewer-wrap {
    padding-top: 0 !important;
    padding-bottom: $mobile-menu-height;
  }

  .doc-index {
    margin: 0;
    padding: 0;
  }

  .account-screen {
  }

  .nav-searchbox input {
    min-width: unset !important;
  }

  .case-viewer {
    &-panel-content {
      gap: 0.75rem;
    }
    &-table-row {
      display: flex;
      flex-direction: column;
    }
    &-table-subhead {
      padding: 0.5rem 1rem;
      border-bottom: 1px solid lightgray;
    }

    &-table-item {
      padding: 0.5rem 1rem;
    }
  }
  .subline {
    border-bottom: 1px solid lightgray;
  }
  .strong {
    font-weight: 700;
  }
  .search-result {
    &-list {
      padding: 0 !important;
      margin: 0 !important;
    }
    &-new-case {
      flex-direction: column;
      gap: 0.25rem;
      &-cell {
        width: 100% !important;
        &.link-dosar {
          padding-block: 0.5rem;
          border-bottom: 1px solid lightgray;
          margin-bottom: 0.5rem;
          position: sticky;
          top: 0;
          background: white;
        }
      }
    }
  }
  .doc-title,
  .lex-doc-viewer-header {
    padding-right: 60px;
  }

  .side-panel-wrap {
    right: 38px !important;
    .panel-button {
      border-radius: 0.25rem !important;
      //transform: translateX(14px);
      width: 32px;
      height: 32px;
      // font-size: 0.8em;
      padding: 0.3em;
    }
  }

  .data-lex-act-content {
    text-align: unset;
  }

  .form-control {
    &:focus {
      border: none !important;
      outline: 0 !important;
      box-shadow: unset !important;
    }
  }

  .batman-advanced-search {
    &-actions {
      padding-bottom: 0;
    }
    &-content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;

      &-panel {
        width: 100%;
      }

      .batman-advanced-search-content-half {
        width: 100%;
      }
    }
    &-more-options.dropdown-toggle {
      padding: 0 0.375em !important;
      font-size: unset !important;
      background: none !important;
      border: none;
      color: #212529;
      &::after {
        display: none;
      }
    }
  }
}

.lex-errors.lex-mobile {
}

.card.home-widget {
  height: max-content;
  padding: 0.5rem !important;
  margin: 0 !important;
}
