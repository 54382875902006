.lex-banner {
  border-radius: 0.5em;
  padding: 1em 1em 1em 2em;
  width: 30em;
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-close-icon {
    font-size: 1.5em;
    cursor: pointer;
    &:hover,
    &:focus {
      color: rgb(200, 200, 200);
    }
  }

  &-details {
    border-top: 1px solid white;
    margin-top: 0.5em;
    padding-top: 0.5em;
  }

  &.error {
    background-color: red;
    color: white;

    .lex-banner-close-icon {
      &:hover,
      &:focus {
        color: red;
        border-radius: 50%;
        background-color: white;
      }
    }
  }

  &.warning {
    background-color: rgb(248, 196, 99);
    color: black;

    .lex-banner-close-icon {
      &:hover,
      &:focus {
        color: rgb(248, 196, 99);
        border-radius: 50%;
        background-color: black;
      }
    }
  }

  &.info {
    background-color: #373e4a;
    color: white;
  }
}
