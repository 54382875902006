
$blue:  #008cff;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$metallic-gray: #373e4a;
$metallic-darkgray: #2b2f32;
$metallic-lightgray: #9da5bd;
$metallic-lightlightgray: #d9dee5;
$metallic-superlightgray: #e8eaf2;
$metallic-ultralightgray: #f1f4f8;

$subnav-color: #eff5f8;

$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  metallic: $metallic-gray,
  metallic-dark: $metallic-darkgray,
  metallic-light: $metallic-lightgray,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) !default;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1200px
) !default;
