.adv {
  position: absolute;
  top:40;
  width: 100%;
  transform-origin: top;
  transition: opacity 200ms ease-in, transform 200ms ease;
}
.advancedSearch-enter {
  opacity: 0.01;
  // max-height: 0;
  transform: scaleY(0);
  transform-origin: top;
}

.advancedSearch-enter.advancedSearch-enter-active {
  opacity: 1;
  // max-height: 1000px;
  transform: scaleY(1);
}

.advancedSearch-leave {
  opacity: 1;
  transform: scaleY(1);
}

.advancedSearch-leave.advancedSearch-leave-active {
  opacity: 0.01;
  transform: scaleY(0);
  // transition:  opacity 200ms ease-in, transform 200ms ease;
}