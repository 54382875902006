.lex-tooltip {
  max-width: 30% !important;
  z-index: 9999;

  .tooltip-inner {
    background-color: #272c34;
    max-width: unset;
  }

  &-pl .tooltip-inner {
    padding: 0.5em 1em;
  }

  &-pxl .tooltip-inner {
    padding: 1em 1.5em;
  }

  &-lighter {
    .tooltip-inner {
      // background-color: #8690ae;
    }
  }
}
