.lex-parent {
    .lex-parent {
        // disable duplicate padding
        padding: unset;
    }
}

.lex-title {
    // font-size: 1.5em;
    margin-top: 1em;
}

.lex-subtitle {
    // font-size: 1.25em;
    margin-top: 1em;
}

.lex-emphasize {

    font-size: 1.125em;
}

.lex-large {
    font-size: 1.125em;
}

.lex-para {
    font-size: 1em;
}

.lex-small {
    font-size: 0.875em;
}

h1,
h2,
h3,
h4,
p {
    padding: 0.25rem 0.5rem !important;
}

p {
    margin-bottom: 0.25rem !important;
}

li {
    p {
        margin: unset;
        padding: unset !important;
    }
}

table {
    width: 100%;
    margin: 0.5rem 0;

    td {

    }
}
