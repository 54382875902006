.subsection-content {
  // transform-origin: top;
  transition: opacity 200ms ease-in, width 200ms ease;
  margin-right:0px;
  // width:100%;//calc(100% - 260px);
  height:100%;
  overflow-y: scroll;
  background-color: $gray-100;
}

.subsection {
  background-color: $metallic-lightlightgray;
  // width:100%;
  height:100%;
  transition: opacity 200ms ease-in, width 200ms ease;  
  .subsection-item {
    cursor: pointer;
    display: block;width:100%;
    width:100%;
    color: $metallic-lightgray;
    padding-left:10px;
    padding-right:10px;
    border-left: 3px solid $metallic-lightlightgray;
    &.selected, &:hover {
      background-color: $metallic-superlightgray;
      border-left: 3px solid $blue;
      font-weight: bold;
    }
  }
}

.subsectionContentAnimated-enter {
  opacity: 0.01;
  // max-height: 0;
  // transform: scaleX(0);
  width:0;
  transform-origin: top;
}

.subsectionContentAnimated-enter.subsectionContentAnimated-enter-active {
  opacity: 1;
  // max-height: 1000px;
  // transform: scaleX(1);
  width:100%;
}

.subsectionContentAnimated-leave {
  opacity: 1;
  // transform: scaleX(1);
  width:100%;
}

.subsectionContentAnimated-leave.subsectionContentAnimated-leave-active {
  opacity: 0.01;
  // transform: scaleX(0);
  width:0;
  // transition:  opacity 200ms ease-in, transform 200ms ease;
}

.subsectionAnimated-enter {
  opacity: 0.01;
  // max-height: 0;
  // transform: scaleX(0);
  width:0;
  transform-origin: top;
}

.subsectionAnimated-enter.subsectionAnimated-enter-active {
  opacity: 1;
  // max-height: 1000px;
  // transform: scaleX(1);
  width:100%;
}

.subsectionAnimated-leave {
  opacity: 1;
  // transform: scaleX(1);
  width:100%;
}

.subsectionAnimated-leave.subsectionAnimated-leave-active {
  opacity: 0.01;
  // transform: scaleX(0);
  width:0;
  // transition:  opacity 200ms ease-in, transform 200ms ease;
}