.lex-errors {
  position: absolute;
  top: 1em;
  overflow: hidden;
  height: max-content !important;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}
