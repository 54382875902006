ul {
  list-style: disc;

  &[data-type="squareBulletList"] {
      list-style: none;

      & > li:before
      {
          position: absolute;
          content: "\2610";
          transform: translateX(-200%); // note this is -150% in the editor
      }
  }

  &[data-type="emptyCircleBulletList"] {
      list-style: circle;
  }

  &[data-type="tickBulletList"] {
      list-style: none;
      & > li:before {
          position: absolute;
          transform: translateX(-200%); // note this is -150% in the editor
          content: "✓";
      }
  }
}
