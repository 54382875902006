span[data-type="lex-custom-tab"] {
  display: inline-block;
  min-width: 2rem;
  max-width: 2rem;
  height: 1rem;
}

@media print {
  span[data-type="lex-custom-tab"] {
    display: inline-block;
    min-width: 2rem;
    max-width: 2rem;
    height: 1rem;
  }
}
