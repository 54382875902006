@media print {
  $print-page-size: auto;
  $print-body-min-width: 100%;
}

@media screen {
  // My Bootstrap configuration
  @import "custom_bootstrap";

  // Bootstrap library
  // @import 'bootstrap/scss/bootstrap';
  @import "~bootstrap/scss/bootstrap.scss";

  @import "advanced_search_anim";
  @import "subsection_content_anim";
  @import "login";
  @import "account";

  .warning {
    color: $yellow;
  }
  .primary {
    color: $blue;
  }
  .success {
    color: $green;
  }
  .danger {
    color: $red;
  }

  @for $i from 5 through 70 {
    .fs-#{$i} {
      font-size: 0px + $i !important;
    }
  }
  .thin {
    font-weight: 100;
  }
  .bold {
    font-weight: bold;
  }
  .cursor-hand {
    cursor: pointer;
  }
  .white {
    color: white;
  }
  $danger-statuses: A I;
  $warning-statuses: B L O v;
  $info-statuses: C M;
  $success-statuses: V P R r;

  .status {
    color: $gray-400;
  }

  @each $status in $danger-statuses {
    .status-#{$status} {
      color: $red;
    }
  }
  @each $status in $warning-statuses {
    .status-#{$status} {
      color: $yellow;
    }
  }
  @each $status in $info-statuses {
    .status-#{$status} {
      color: $teal;
    }
  }
  @each $status in $success-statuses {
    .status-#{$status} {
      color: $green;
    }
  }

  html,
  body,
  #root,
  #root > div {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }

  .left-sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 60px;
    z-index: $zindex-fixed + 100;
    overflow-x: visible;
    .menu,
    .content {
      display: none;
    }
    &.opened {
      width: 100%;
      .menu,
      .content {
        display: block;
      }
    }
    .buttons {
      width: 60px;
      height: 100%;
      background-color: $metallic-gray;
      .btn {
        font-size: 30px;
        height: 60px;
        width: 60px;
        color: $metallic-lightgray;
      }
      .btn-help {
        margin-bottom: 2rem;
      }
    }

    .subsections {
      position: absolute;
      left: 60px;
      top: 0;
      padding-top: 0px;
      // width:100%;
      bottom: 0;
      right: 0;
      .menu {
        width: 200px;
        height: 100%;
        white-space: nowrap;
        float: left;
      }
      .content {
        width: calc(100% - 200px);
        height: 100%;
        float: left;
      }
      // > span {
      //   display:inline-block;
      //   height:100%;
      //   // width:calc(100% - 260px);
      // }
    }
  }

  .navbar {
    padding-left: 0rem;
    padding-right: 0;

    .btn-home {
      color: $blue;
      font-size: 30px;
      margin-right: 1.5rem;
    }
    // max-height: 60px;

    // height:60px;

    .btn-profile {
      font-size: 34px;
    }
    .btn-profile,
    .btn-home {
      height: 60px;
      width: 60px;
      @include border-radius(0);
    }
  }

  .nav-searchbox {
    position: relative;
    &.advanced-visible {
      .search-controls-wrap {
        input,
        .btn {
          @include border-bottom-radius(0);
        }
      }
    }
    .search-controls-wrap {
      height: 40px;
      > * {
        height: 40px;
      }
      input {
        min-width: 400px;
        margin-right: -5px;
      }
      .btn {
        cursor: "pointer";
      }
      .btn-search {
        width: 40px;
        position: relative;
        margin-right: -5px;
        z-index: 2;
      }
      .btn-adv-search {
        position: relative;
        z-index: 1;
      }
    }
  }

  #advanced-search {
    .card {
      background-color: #e8eaf2;
      @include border-top-radius(0);
      border-top: 0;
    }
  }

  ul.react-autosuggest__suggestions-list {
    max-height: 200px;
    overflow-y: scroll;
  }

  .search-result {
    .result-wrap {
      border: 1px solid $gray-200;
    }
    .about > * {
      margin-right: 0.25rem;
      display: inline-block;
    }

    .type-label {
      color: $black;
      font-weight: bold;
      font-size: 12px;
    }
    .issue-nr-label {
      color: $blue;
      font-size: 12px;
      font-weight: normal;
    }
    .issuer-label {
      color: $black;
      font-weight: normal;
      font-size: 12px;
      font-style: italic;
    }
    .title-label {
      font-size: 13px;
      color: $black;
    }
    .status-label {
      font-size: 12px;

      .status {
        font-weight: 100;
      }
    }
  }

  .doc-index {
    margin: 0.75rem;
    .title {
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      text-transform: uppercase;
      color: $metallic-lightgray;
    }

    .node,
    .leaf {
      max-width: 100%;
      @for $i from 1 through 4 {
        &.depth-#{$i} > .collapser {
          font-size: 19px - $i !important;
        }
      }

      display: block;
      // border-left: 2px solid $metallic-lightgray;
      padding-left: 1.5rem;
      padding-right: 0; //1.5rem;
      margin-bottom: 0.5rem;
      .collapser {
        cursor: pointer !important;
        font-weight: 100;
        color: $blue;
        // >.toggler{
        //   display:none
        // }
        > .doc-count {
          color: $metallic-lightgray;
          display: block;
        }
      }
    }
    .depth-1 {
      padding-left: 0.25rem;
    }
    .doc {
      margin-bottom: 0.5rem;
      .about {
        font-size: 12px;
        // font-weight:bold;
        > * {
          margin-right: 0.25rem;
          display: inline-block;
        }
        .type-label {
          // color:$black;
          font-weight: bold;
        }
        .issuer-label {
          // color:$black;
          font-weight: bold;
        }
      }
      .doctitle {
        font-size: 12px;
        white-space: normal;
        color: $black;
      }
      a {
        white-space: normal !important;
        color: $metallic-gray;
        font-size: 13px;
        // &:hover {
        //   text-decoration: underline!important;
        // }
      }
    }
    .children_wrap,
    .docs_wrap {
      padding-left: 10px;
      margin-bottom: 0;
      list-style: none;
      .collapser {
        cursor: pointer !important;
      }
      .toggler {
        margin-right: 5px;
        color: #337ab7 !important;
        font-size: 18px;
      }
      .docs th {
        text-align: right;
        padding-right: 8px;
      }
      .docs .type_and_number {
        /* white-space: nowrap; */
        font-size: 14px;
      }
      .docs .title {
        color: #337ab7;
      }
    }
  }

  .side-panel-wrap {
    .panel-button {
      width: 40px;
      height: 40px;
    }
    // &.lefty,
    // &.lefty .panel-button,
    // &.righty .side-panel-body-wrap {
    //   left:0;
    // }

    // &.righty .side-panel-body-wrap {
    //   left: unset;
    //   right: 100%;
    // }

    // &.righty,
    // &.righty .panel-button,
    // &.lefty .side-panel-body-wrap {
    //   right:0;
    // }
    // &.righty {
    //   .side-panel {
    //     left: calc(-100% - 40px);
    //     transition: left 0ms 0ms;
    //     &.hidden {
    //       left:0
    //     }
    //   }
    // }
    // &.lefty {
    //   .side-panel {
    //     right: calc(-100% - 40px);
    //     transition: right 0ms 0ms;
    //     &.hidden {
    //       right:0
    //     }
    //   }
    // }
    .side-panel {
      background-color: $metallic-ultralightgray;
    }
    .panel-title {
      font-size: 14px;
      margin: 0.25rem;
      // border-bottom:1px solid $metallic-lightgray;
    }
    .panel-body {
      // border:5px solid #eee;
      padding: 0.25rem;
      // background-color: $metallic-ultralightgray;
      table td {
        padding: 0.25rem 0.75rem;
        vertical-align: middle;
      }
      .review-link {
        color: $blue;
        font-size: 12px;
      }
    }
  }
  .navbar .container {
    flex-wrap: nowrap;
    display: block;
  }

  .subnav {
    background-color: $subnav-color;
    position: relative;
    .doc-description {
      padding: 0.5rem 0;
      &.minimized {
        padding: 0.25rem 0;
        .doc-title {
          display: none;
        }
      }
      .doc-title {
        color: $blue;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 0.25rem;
      }
      .publish-text,
      .emitenti-text,
      .type-nran-text {
        font-size: 12px;
        font-weight: normal;
      }
      .emitenti-text {
        font-weight: bold;
      }
    }
    .doc-tools-underwrapper {
      position: absolute;
      height: 38px;
      bottom: -38px;
      width: 100%;
      background-color: white;
      border-bottom: 1px solid $metallic-superlightgray;
      &.minimized {
        height: 30px;
        bottom: -30px;
      }
      .doc-tools {
        position: absolute;
        height: 38px;
        bottom: -38px;
        width: 100%;
        .doc-tools-container {
          height: 100%;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
        }
        .input-group {
          height: auto;
        }
        &.minimized {
          height: 30px;
          bottom: -30px;
          .input-group,
          input {
            height: 24px;
          }
          .doc-tools-container {
            padding-top: 1px;
            padding-bottom: 1px;
          }
          .doc-tools-button,
          .btn {
            font-size: 12px;
          }
        }
        .doc-tools-button {
          color: $metallic-lightgray !important;
          font-size: 14px;
          cursor: pointer;
          padding: 0.25rem 0.5rem;
          i {
            color: $blue;
          }
        }
      }
    }

    .better-doc-tools-wrapper {
      width: 100%;
      background-color: white;
      border-bottom: 1px solid $metallic-superlightgray;
      .doc-tools {
        width: 100%;
        .doc-tools-container {
          height: 100%;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
        }
        .input-group {
          height: auto;
        }
        &.minimized {
          .input-group,
          input {
            height: 24px;
          }
          .doc-tools-container {
            padding-top: 1px;
            padding-bottom: 1px;
          }
          .doc-tools-button,
          .btn {
            font-size: 12px;
          }
        }
        .doc-tools-button {
          color: $metallic-lightgray !important;
          font-size: 14px;
          cursor: pointer;
          padding: 0.25rem 0.5rem;
          i {
            color: $blue;
          }
        }
      }
    }
  }

  #fulldoc {
    .lex-preview-content-header {
      display: none;
    }
  }

  #docviewer-wrap {
    // margin-top:2rem;
    position: relative;
    padding-top: 2.75rem;
    // padding-bottom: 0.75rem;
  }

  #search-page {
    background-color: $metallic-ultralightgray;
  }

  .home {
    // min-height: 100%;
    // max-height: calc(100% - 60px);
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $metallic-ultralightgray;
    .home-widget {
      width: 100%;
      .title {
        color: $metallic-lightgray;
      }
      .count {
        color: $blue;
      }
      // .doctitle {
      //   width:100%;
      //   overflow: hidden;
      //   white-space: nowrap;
      //   text-overflow: ellipsis;
      //   display:block;
      //   color: $black;
      //   font-size: 12px;
      //   line-height: 16px;
      // }
    }
  }

  .track-see-btn {
    .warning,
    &:hover .primary {
      display: inline-block;
    }
    .primary,
    &:hover .warning {
      display: none;
    }
  }

  // subnav redo:
  .doc-title,
  .lex-doc-viewer-header {
    background-color: $subnav-color;
    width: 100%;
    // width: 50%;
    // align-self: center;
  }
  .doc-title {
    color: $blue;
    font-size: 18px;
    line-height: 20px;
    padding-block: 0.25em;
  }
  .lex-doc-viewer-header {
    padding-block: 0.25em;

    .publish-text,
    .emitenti-text,
    .type-nran-text {
      font-size: 12px;
      font-weight: normal;
    }
    .emitenti-text {
      font-weight: bold;
    }
  }
}
